<template>
  <div class="base-footer">
    <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
    <a-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
      <a-row :gutter="40">
        <a-col :sm="24" :md="24" :lg="6">
          <div class="logo">
            <img :src="require('@/assets/logo.png')" alt="logo" />
          </div>
        </a-col>
        <a-col :sm="12" :md="12" :lg="6">
          <dl>
            <dt>产品</dt>
            <dd>
              <p><router-link to="/">插件</router-link></p>
              <p><router-link to="/">企业版</router-link></p>
              <p><router-link to="/">更新日志</router-link></p>
              <p><router-link to="/">产品下载</router-link></p>
            </dd>
          </dl>
          <dl>
            <dt>支持</dt>
            <dd>
              <p><router-link to="/">帮助中心</router-link></p>
              <p><router-link to="/">讨论区</router-link></p>
            </dd>
          </dl>
        </a-col>
        <a-col :sm="12" :md="12" :lg="6">
          <dl>
            <dt>关于</dt>
            <dd>
              <p><router-link to="/">帮助中心</router-link></p>
              <p><router-link to="/">使用条款</router-link></p>
              <p><router-link to="/">隐私协议</router-link></p>
            </dd>
          </dl>
          <dl>
            <dt>更多</dt>
            <dd>
              <p><router-link to="/">邀请他人协助开发</router-link></p>
            </dd>
          </dl>
        </a-col>
        <a-col :sm="24" :md="24" :lg="6">
          <dl>
            <dt>联系我们</dt>
            <dd>
              <p>如需帮助，请发送邮件<br/>至xxxxx@xxx.com</p>
              <p>商务合作：<br/>bd@xxxxxx.com</p>
            </dd>
          </dl>
        </a-col>
      </a-row>
      <p class="copyright">
        <span>{{ this.$store.state.appInfo.copyright }}</span>
        <br/>
        <span>{{ this.$store.state.appInfo.beian }}</span>
      </p>
    </a-col>
    <a-col :xs="0" :sm="1" :md="2" :lg="3" :xl="4"></a-col>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  data() {
    return {
      
    };
  },
};
</script>

<style lang="scss">
.base-footer {
  padding-top: 70px;
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 220px;
    img {
      width: 80%;
      height: auto;
    }
  }
  dl {
    margin-bottom: 30px;
    dt {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #182634;
    }
    dd {
      p {
        margin: 0;
        line-height: 30px;
        color: #7E8791;
      }
      a {
        color: #7E8791;
        &:hover {
          color: #0071BC;
        }
      }
    }
  }
  .copyright {
    margin: 40px 0 0;
    padding-top: 20px;
    text-align: center;
    font-size: 12px;
    color: #959595;
    border-top: 1px solid #E3E3EA;
  }
}
</style>
