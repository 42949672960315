<template>
  <div class="base-layout">
    <a-layout>
      <a-layout-header>
        <base-header />
      </a-layout-header>
      <a-layout-content>
        <base-main />
      </a-layout-content>
      <a-layout-footer>
        <base-footer />
      </a-layout-footer>
    </a-layout>
    <a-back-top />
  </div>
</template>

<script>
import BaseHeader from './components/BaseHeader';
import BaseMain from './components/BaseMain';
import BaseFooter from './components/BaseFooter';
import { ENV, Storage } from '@/utils';
export default {
  name: 'BaseLayout',
  components: {
    BaseHeader,
    BaseMain,
    BaseFooter,
  },
  mounted() {
    this.tokenAuth();
  },
  methods: {
    tokenAuth() {
      const token = Storage.get(ENV.storage.token);
      if (token) {
        this.$store.dispatch('tokenAuth', token);
      }
    }
  }
};
</script>

<style lang="scss">
.base-layout {
  .ant-layout-header {
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding: 0;
    background: #fff;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }
  .ant-layout-content {
    padding-top: 60px;
  }
  .ant-layout-footer {
    background: #fff;
  }
}
</style>
