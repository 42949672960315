<template>
  <div :class="['base-header', { 'sidebar-open': showSideBar }]">
    <a-row>
      <a-col :xs="0" :sm="0" :md="1" :lg="1" :xl="3"></a-col>
      <a-col :xs="24" :sm="24" :md="22" :lg="22" :xl="18">
        <a-row>
          <a-col :xs="3" :sm="3" :md="0" :lg="0" :xl="0">
            <div class="toggle-sidebar-button" @click="toggleSideBar">
              <div class="icon">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </a-col>
          <a-col :xs="6" :sm="6" :md="0" :lg="0" :xl="0">
            <router-link to="/" class="logo mini">
              <img :src="require('@/assets/favicon.png')" alt="favicon" />
            </router-link>
          </a-col>
          <a-col :xs="0" :sm="0" :md="6" :lg="6" :xl="6">
            <router-link to="/" class="logo">
              <img :src="require('@/assets/logo.png')" alt="logo" />
            </router-link>
          </a-col>
          <a-col :xs="0" :sm="0" :md="12" :lg="12" :xl="12">
            <div class="menu">
              <a-menu mode="horizontal" @click="handleClickMenu">
                <a-menu-item 
                  v-for="menu in menus" 
                  :key="menu.id"
                >
                  <router-link v-if="menu.router" :to="menu.router">{{menu.name}}</router-link>
                  <a v-if="menu.href" :href="menu.href" target="_blank">{{menu.name}}</a>
                </a-menu-item>
              </a-menu>
            </div>
          </a-col>
          <a-col :xs="15" :sm="15" :md="6" :lg="6" :xl="6" class="text-align-right">
            <a-button
              v-if="this.$store.state.auth && this.$store.state.userInfo.detail && this.$store.state.userInfo.detail.role === 'admin'"
              @click="goAdmin"
              >进入管理区</a-button
            >
            <a-button
              type="primary"
              v-if="this.$store.state.auth"
              @click="goProjects"
              >进入工作区</a-button
            >
            <div v-else>
              <router-link to="/user/login"
                ><a-button>登录</a-button></router-link
              >
              <router-link to="/user/register"
                ><a-button type="primary">免费注册</a-button></router-link
              >
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="0" :sm="0" :md="1" :lg="1" :xl="3"></a-col>
    </a-row>

    <div class="sidebar">
      <a-menu @click="handleClickMenu">
        <a-menu-item 
          v-for="menu in menus" 
          :key="menu.id"
        >
          <router-link v-if="menu.router" :to="menu.router">{{menu.name}}</router-link>
          <a v-if="menu.href" :href="menu.href" target="_blank">{{menu.name}}</a>
        </a-menu-item>
      </a-menu>
    </div>

  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  data() {
    return {
      activeKey: null,
      showSideBar: false,
      screenWidth: null,
      menus: [
        { name: '首页', id: 'home', router: '/' },
        { name: '功能描述', id: 'introduce', router: '/introduce' },
        // { name: '企业版', id: 'enterprise', router: '/enterprise' },
        // { name: '下载', id: 'download', router: '/download' },
        { name: '使用文档', id: 'help', href: 'https://doc.apimini.com' },
        { name: '价格', id: 'price', router: '/price' },
        { name: '交流', id: 'community', router: '/community' },
        { name: '关于ApiMini', id: 'about', href: 'https://doc.apimini.com/introduce/' },
        // { name: '讨论区', id: 'discuss', href: 'https://www.apimini.com' },
      ],
    };
  },
  mounted() {
    this.activeKey = this.$route.name;
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth > 760 && this.showSideBar) {
          this.showSideBar = false;
        }
      })();
    }
  },
  methods: {
    handleClickMenu({ key }) {
      this.activeKey = key;
      this.showSideBar = false;
    },
    goAdmin() {
      this.$router.push('/admin');
    },
    goProjects() {
      this.$router.push('/projects');
    },
    toggleSideBar() {
      this.showSideBar = !this.showSideBar;
    }
  },
};
</script>

<style lang="scss">
.base-header {
  .logo {
    display: block;
    img {
      width: auto;
      height: 30px;
    }
    &.mini {
      padding: 0 1rem;
    }
  }
  .ant-menu.ant-menu-horizontal {
    border: none;
    .ant-menu-item {
      display: inline-block;
      float: none;
      padding: 0;
      border: none;
      a {
        display: block;
        padding: 0 20px;
        font-size: 15px;
        height: 61px;
        line-height: 62px;
        color: #182634;
        border-bottom: 2px solid transparent;
        &.router-link-exact-active {
          color: #0071bc;
          border-color: #0071bc;
        }
      }
    }
  }
  // .menu {
  //   padding: 8px 0 6px;
  // }
  .btn-group {
    text-align: right;
  }
  .ant-btn {
    margin: 0 5px;
    padding: 0 24px;
    height: 40px;
    border-radius: 20px;
  }
  .ant-btn-primary {
    background: var(--oragin-color);
    border-color: var(--oragin-color);
  }

  .toggle-sidebar-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 1rem;
    cursor: pointer;
  }
  .toggle-sidebar-button .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    cursor: inherit;
  }
  .toggle-sidebar-button .icon span {
    display: inline-block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #333;
    transition: transform .3s ease;
  }
  .toggle-sidebar-button .icon span:nth-child(2) {
    margin: 6px 0;
  }
  &.sidebar-open .toggle-sidebar-button .icon {
    span:nth-child(1) {
      transform: rotate(45deg) translate3d(5.5px,5.5px,0);
    }
    span:nth-child(2) {
      transform: scale3d(0,1,1);
    }
    span:nth-child(3) {
      transform: rotate(-45deg) translate3d(6px,-6px,0);
    }
    .icon span:nth-child(1),
    .icon span:nth-child(3) {
      transform-origin: center;
    }
  }

  .sidebar {
    width: 260px;
    padding-top: 20px;
    transform: translate(-100%);
    position: fixed;
    left: 0;
    top: 61px;
    bottom: 0;
    z-index: 99;
    box-shadow: 1px 0px 0px rgb(0 0 0 / 8%);
    background: #fff;
    overflow-y: auto;
    transition: transform .3s ease;
  }
  &.sidebar-open .sidebar {
    transform: translate(0);
  }
}
</style>
