<template>
  <section class="base-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: 'BaseMain',
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
.base-main {
  min-height: 1000px;
  background: #fff;
  overflow: hidden;
}
</style>
